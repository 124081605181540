
const buildLink = (d3, svg, root) => {

  const link = svg.append("g")
    .attr("fill", "none")
    .attr("stroke-opacity", 0.4)
    .attr("stroke-width", 1.5)
    .selectAll()
    .data(root.links())
    .join("path")
    .attr("stroke", d => d.source.data.type != "info" ? "#999" : "black")
    .attr("d", d3.linkVertical()
      .x(d => d.x)
      .y(d => d.y));
  return link;
};

export { buildLink }

