import Swal from 'sweetalert2'

const errorAlertSweet = (text_error, if_reload) => {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    html: `${text_error}`,
    confirmButtonColor: "black",
    customClass: {
    }

  }).then((result) => {
    if (if_reload) {
      location.reload();
    }
  });;
}

const loadingAlertSweet = (timerTime) => {

  let timerInterval;
  Swal.fire({
    title: "Création de votre style en cours",
    html: "Temps restant maximum <b></b>.",
    width: 600,
    timer: timerTime,
    timerProgressBar: true,
    confirmButtonColor: "black",
    padding: "3em",
    color: "black",
    didOpen: () => {
      Swal.showLoading();
      const timer = Swal.getPopup().querySelector("b");
      timerInterval = setInterval(() => {

        timer.textContent = `${Math.floor(Swal.getTimerLeft() / 1000)}`;
      }, 100);
    },
    backdrop: `
          rgb(252, 244, 233,0.8)
          url("/load.gif")
          left top
          no-repeat
        `});
}

export { errorAlertSweet, loadingAlertSweet }
