const redrawChart = (index, chart, globalData, duration, configChartLegend) => {
  // Set new subtitle on every redraw
  const manageUnits = (valueDate, countryName) => {
    var units = [' FLOPS', ' kFLOPS', ' MFLOPS', ' GFLOPS', ' TFLOPS', ' PFLOPS', ' EFLOPS']; // Définir les unités
    var i = 0;
    while (valueDate >= 1000) {
      valueDate /= 1000;
      i++;
    }
    return Math.round(valueDate) + units[i];
    // return units[i]; // Afficher la valeur avec l'unité appropriée
  }
  let textSubtitle = "";
  if (configChartLegend["subtitleEnabled"]) {
    textSubtitle = `${globalData[0].info2[index]} ${globalData[0].info[index]} · ${Highcharts.dateFormat(configChartLegend["dateFormat"], globalData[0].data[index][0])}`;
  }
  chart.setTitle(null, {
    text: textSubtitle
  }, false);

  // [0, 1].forEach(seriesIndex => {
  //   let sentence = ""
  //   if (globalData[seriesIndex].value3[index]) {
  //     sentence = `${globalData[seriesIndex].value3[index]}Md$ ${globalData[seriesIndex].name.charAt(0).toUpperCase() + globalData[seriesIndex].name.slice(1)}`
  //   } else {
  //     sentence = `${globalData[seriesIndex].value3[globalData[seriesIndex].value3.length - 1]}Md$ ${globalData[seriesIndex].name.charAt(0).toUpperCase() + globalData[seriesIndex].name.slice(1)}`
  //   }
  //   chart.series[seriesIndex].update(
  //     {
  //       name: sentence

  //     }
  //     , true);
  // });


  const newValues = globalData.map(series => {
    series.data[index][1]
  });
  const maxIndex = newValues.indexOf(Math.max.apply(null, newValues));



  // To each series, add a point:
  chart.series.forEach(
    (series, seriesIndex) => {
      const enabled = maxIndex === seriesIndex && ((index < 5) || (index % 5 === 0));
      series.addPoint(
        {
          x: globalData[seriesIndex].data[index][0],
          y: globalData[seriesIndex].data[index][1],

          dataLabels: {
            enabled: true,
            animation: false,
            x: 1000,
            y: 0,
          },
          marker: {
            symbol: "circle",
            enabled: true
          }
        },
        false,
        false,
        false
        );
      chart.series.forEach(series => {
        series.points.forEach((point, pointIndex) => {
          if (pointIndex === series.points.length - 1 && point.dataLabel) {
            point.update({
              dataLabels: {
                enabled: false,
                animation: false
              },
              marker: {
                enabled: false
              }
            }, false);
          }
        });
      });

    }
  );

  // Now, once everything is updated, redraw chart:
  chart.redraw({
    duration: duration
  });
}

export { redrawChart }
