const choiceTree = (buttons) => {
  buttons.forEach((button) => {
    button.addEventListener('click', (event) => {
      const listTreeSections = ["num", "cat", "catnum", "geo", "relationnal", "time"];
      const treeSection = event.currentTarget.dataset.treeSection;
      event.currentTarget.classList.add('cta-learn-actived')
      showTree(treeSection);
      buttons.forEach((btn) => {
        if (event.currentTarget != btn) {
          btn.classList.remove('cta-learn-actived')
          hiddenTree(btn.dataset.treeSection)
        }
      })
    })

  })

}

const showTree = (idName) => {
  const svg = document.getElementById(idName);
  svg.classList.remove('d-none')

}
const hiddenTree = (idName) => {
  const svg = document.getElementById(idName);
  svg.classList.add('d-none')

}


export { choiceTree, showTree }
