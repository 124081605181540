import * as Highcharts from 'highcharts/highcharts-gantt';

const gantt = () => {
  const color1 = '#2DA530';
  const color2 = '#FBBC04';
  const color3 = '#03117C';
  var today = new Date(),
    day = 1000 * 60 * 60 * 24,
    each = Highcharts.each,
    reduce = Highcharts.reduce,
    btnShowDialog = document.getElementById('btnShowDialog'),
    btnRemoveTask = document.getElementById('btnRemoveSelected'),
    btnAddTask = document.getElementById('btnAddTask'),
    btnCancelAddTask = document.getElementById('btnCancelAddTask'),
    addTaskDialog = document.getElementById('addTaskDialog'),
    inputName = document.getElementById('inputName'),
    selectDepartment = document.getElementById('selectDepartment'),
    selectDependency = document.getElementById('selectDependency'),
    chkMilestone = document.getElementById('chkMilestone'),
    isAddingTask = false;

// Set to 00:00:00:000 today
today.setUTCHours(0);
today.setUTCMinutes(0);
today.setUTCSeconds(0);
today.setUTCMilliseconds(0);
today = today.getTime();


// Update disabled status of the remove button, depending on whether or not we
// have any selected points.
function updateRemoveButtonStatus() {
  var chart = this.series.chart;
    // Run in a timeout to allow the select to update
    setTimeout(function () {
        btnRemoveTask.disabled = !chart.getSelectedPoints().length ||
            isAddingTask;
    }, 10);
  }
  Highcharts.setOptions({
      lang: {
          months: [
              'Janvier', 'Février', 'Mars', 'Avril',
              'Mai', 'Juin', 'Juillet', 'Août',
              'Septembre', 'Octobre', 'Novembre', 'Décembre'
          ],
          weekdays: [
              'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
              'Jeudi', 'Vendredi', 'Samedi'
          ]
      }
  });

  // Create the chart
  var chart = Highcharts.ganttChart('container-gantt', {
    chart: {
        spacingLeft: 1
    },
    title: {
        text: 'Construisons ensemble votre projet',
        style: {
          fontFamily: 'Inter',
          fontSize: '2em',
          fontWeight: 'bolder'
        }
    },
    subtitle: {
      text: 'Le projet est décomposé en tâches spécifiques pour une efficacité optimale',
    },
    lang: {
        accessibility: {
            axis: {
                xAxisDescriptionPlural: 'Le graphique a un axe X en deux parties montrant le temps en semaines et en jours.'
            }
        },
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        shortMonths: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
        rangeSelectorZoom: 'Période',
        resetZoom: 'Réinitialiser le zoom'
    },
    accessibility: {
        point: {
            descriptionFormatter: function (point) {
                return Highcharts.format(
                    point.milestone ?
                        '{point.name}, étape importante pour {point.yCategory} le {point.x:%d %B %Y}.' :
                        '{point.name}, attribué à {point.yCategory} du {point.x:%d %B %Y} au {point.x2:%d %B %Y}.',
                    { point }
                );
            }
        }
    },
    plotOptions: {
        series: {
            animation: false, // Do not animate dependency connectors
            dragDrop: {
                draggableX: true,
                draggableY: true,
                dragMinY: 0,
                dragMaxY: 2,
                dragPrecisionX: day / 3 // Snap to eight hours
            },
            dataLabels: {
                enabled: true,
                format: '{point.name}',

                style: {
                    cursor: 'default',
                    pointerEvents: 'none',
                    textOutline: 'none'
                }
            },
            allowPointSelect: true,
            // point: {
            //     events: {
            //         select: updateRemoveButtonStatus,
            //         unselect: updateRemoveButtonStatus,
            //         remove: updateRemoveButtonStatus
            //     }
            // }
        }
    },
    yAxis: {
        type: 'category',
        categories: ['Expression du besoin', 'Développement', 'Testing'],
        accessibility: {
            description: 'Départements de l\'organisation'
        },
        min: 0,
        max: 2
    },
    xAxis: {

        categories: ["semain 1", "semaine 2"],
        currentDateIndicator: false,
        type: 'category',
        labels: {
            enabled: false
        },
        tickInterval: 1000 * 60 * 60 * 24 * 7,
        grid: {
          enabled: false
        }
    },
    tooltip: {
        xDateFormat: '%A %d %B %Y'
    },
    series: [{
        name: 'Exemple de gestion de projet',
        data: [
        {
            start: today + 2 * day,
            end: today + day * 4,
            name: 'Cahier des charges',

            id: 'charge',
            color: color1,
            y: 0
        }, {
            start: today + day * 4,
            end: today + day * 6,
            id: 'dev-1',
            dependency: 'charge',
            name: "Développement de la brique 1",
            color: color2,
            y: 1
        }, {
            start: today + day * 6,
            end: today + day * 7,
            name: "Test brique 1",
            dependency: 'dev-1',
            color: color3,
            id:"test-1",
            y: 2
        }, {
            start: today + day * 7,
            name: 'Brique 1 terminé',
            milestone: true,
            color: color1,
            id: 'charge_done',
            y: 0
        },
        {
            start: today + day * 7,
            end: today + day * 9,
            name: 'Développement de la brique 2',
            id: "dev-2",
            color: color2,
            dependency: 'test-1',
            y: 1
        }, {
            start: today + day * 9,
            end: today + day * 10,
            name: 'Test brique 2',
            id: 'test-2',
            color: color3,
            dependency: 'dev-2',
            y: 2
        },
        {
            start: today + day * 10,
            end: today + day * 11,
            name: "Livraison",
            color: color1,
            dependency: 'test-2',
            y: 0
        }]
    }]
});


  /* Add button handlers for add/remove tasks */
  if (btnRemoveTask) {

    btnRemoveTask.onclick = function () {
        var points = chart.getSelectedPoints();
        each(points, function (point) {
            point.remove();
        });
    };
  }
  if (btnShowDialog) {
    btnShowDialog.onclick = function () {
        // Update dependency list
        var depInnerHTML = '<option value=""></option>';
        each(chart.series[0].points, function (point) {
            depInnerHTML += '<option value="' + point.id + '">' + point.name +
                ' </option>';
        });
        selectDependency.innerHTML = depInnerHTML;

        // Show dialog by removing "hidden" class
        addTaskDialog.className = 'overlay';
        isAddingTask = true;

        // Focus name field
        inputName.value = '';
        inputName.focus();
    };

  }
  if (btnAddTask) {
    btnAddTask.onclick = function () {
        // Get values from dialog
        var series = chart.series[0],
            name = inputName.value,
            undef,
            dependency = chart.get(
                selectDependency.options[selectDependency.selectedIndex].value
            ),
            y = parseInt(
                selectDepartment.options[selectDepartment.selectedIndex].value,
                10
            ),
            maxEnd = reduce(series.points, function (acc, point) {
                return point.y === y && point.end ? Math.max(acc, point.end) : acc;
            }, 0),
            milestone = chkMilestone.checked || undef;

        // Empty category
        if (maxEnd === 0) {
            maxEnd = today;
        }

        // Add the point
        series.addPoint({
            start: maxEnd + (milestone ? day : 0),
            end: milestone ? undef : maxEnd + day,
            y: y,
            name: name,
            dependency: dependency ? dependency.id : undef,
            milestone: milestone
        });

        // Hide dialog
        addTaskDialog.className += ' hidden';
        isAddingTask = false;
    };

  }
  if (btnCancelAddTask) {
    btnCancelAddTask.onclick = function () {
        // Hide dialog
        addTaskDialog.className += ' hidden';
        isAddingTask = false;
    };
  }

}

export { gantt }

