import { Controller } from "@hotwired/stimulus";


import { dataNumericGet } from "../components/data/data";
import { choiceTree,   showTree } from "../components/dataviz/d3/tree/choiceTree";
import { createTree } from "../components/dataviz/d3/tree/createTree";

// Connects to data-controller="choice-dataviz"
export default class extends Controller {
  connect() {

    const dataNumerics = JSON.parse(this.element.dataset.tree)
    dataNumerics.forEach((dataNumeric) => {
      createTree(dataNumeric, dataNumeric["idname"], this.element)
      if (dataNumeric["idname"] == "num") {
          showTree("num")
      }

    })
    const buttons = document.getElementById('tree-button-container').querySelectorAll('button');
    choiceTree(buttons)




  }
}
