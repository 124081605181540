import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="list-continue"
export default class extends Controller {

  connect() {
    // function listContinue(element) {
    //   element
    //   // console.log(bulletBig)
    //   console.log(bulletBig)
    // }
    // setInterval(listContinue, 2000, this.element)
  }
}
