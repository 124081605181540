import { Controller } from "@hotwired/stimulus"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"

// Connects to data-controller="address-autocomplete-mobile"
export default class extends Controller {
  static values = { apiKey: String }
  static targets = ["addressmobile"]
  connect() {
    this.geocoder = new MapboxGeocoder({
      accessToken: this.apiKeyValue,
      countries: 'fr',
      types: "country,region,place,postcode,locality,neighborhood,address"
    })

    this.geocoder.addTo(this.element)
    let hidden_value_address = document.querySelector(".form-search-mobile #address").value
    if (hidden_value_address == "") {
      hidden_value_address = "Entrez une adresse"
    }
    document.querySelector(".form-search-mobile .mapboxgl-ctrl-geocoder--input").placeholder = hidden_value_address
    this.geocoder.on("result", event => this.#setInputValue(event))
    this.geocoder.on("clear", () => this.#clearInputValue())

  }
  #setInputValue(event) {
    console.log(event.result["place_name"])

    this.addressmobileTarget.value = event.result["place_name"]
  }

  #clearInputValue() {
    this.addressmobileTarget.value = ""
  }
  disconnect() {
    this.geocoder.onRemove()
  }
}
