// import { min } from "d3";
import { frChart } from "./local/frChart";
const createChart = (chart, configGraphic, globalData, duration, startIterator, configChart) => {
  frChart();
  function format(y) {
    return y < 0.01 ? '<0.01' : '' + y.toFixed(2);
  }
  const maxValue = globalData.reduce((maxValue, obj) => {
    const objMax = Math.max(...obj.data.map(item => item[1]));
    return Math.max(maxValue * 1.01 , objMax);
  }, -Infinity);
  const minValue = globalData.reduce((minValue, obj) => {
    const objMin = Math.min(...obj.data.map(item => item[1]));
    return Math.min(minValue / 1.01 , objMin);
  }, Infinity);


  let textSubtitle = ''
  if (configChart["subtitleEnabled"]) {
    textSubtitle = `${globalData[0].info2[0]} ${globalData[0].data[1][0]} · ${Highcharts.dateFormat(configChart["dateFormat"], globalData[0].data[0][0])}`
  }


  chart = Highcharts.chart('container-story-time', {
    chart: {
      spacingLeft: 10, // Espace à gauche du graphique
      spacingRight: 10, // Espace à droite du graphique
      spacingTop: 30, // Espace en haut du graphique
      spacingBottom: 30, // Espace en bas du graphique
      type: 'line',
      width: 500, // Largeur fixe du graphique
      height: '100%',
      marginLeft: 100,
      backgroundColor: configGraphic["colors"]["main"],
      events: {
        load: function () {
          this.renderer.image(
            `${window.origin}/logo-full.png`,
            this.chartWidth - 195, // Position X (100 pixels à partir de la droite)
            this.chartHeight - 48, // Position Y (50 pixels du bas)
            180// Hauteur de l'image
          ).add();
        },

      },

    },
    caption: {
      text: configChart["source"],
      style: {
        color: configGraphic["colors"]["secondary"],
        fontWeight: '400',
        fontFamily: 'Inter, sans-serif',
        fontSize: configGraphic["fontSizesSet"]["min"],
      },
    },
    credits: {
      enabled: false
    },
    navigation: {
      buttonOptions: {
        enabled: false
      }
    },
    legend: {
      layout: 'proximate',
      align: 'right',

      verticalAlign: 'top',
      // symbolWidth: 0,
      // symbolHeight: 0,
      // symbolRadius: 0,
      // symbolOpacity: 0.5,
      enabled: configChart["legendEnabled"],
      useHTML: true,
      itemStyle: {
        color: 'white',
        fontWeight: '400',
      },
    },
    title: {
      text: configChart["title"],
      margin: 50,
      style: {
        fontFamily: 'Inter, sans-serif', // Choisir la famille de polices (Arial dans cet exemple)
        color: configGraphic["colors"]["secondary"],
        fontSize: configGraphic["fontSizesSet"]["max"],
        fontWeight: 'bold',
        align: 'center'
      }
    },
    subtitle: {
      enabled: configChart["subtitleEnabled"],
      floating: true,
      align: 'right',
      y: 60,
      x: 0,
      text: textSubtitle,
      style: {
        fontSize: configGraphic["fontSizesSet"]["subtitle"],
        color: configGraphic["colors"]["secondary"],
        fontWeight: '400',
        fontFamily: 'Inter, sans-serif',

      }
    },
    tooltip: {
      split: true,
      pointFormatter: function () {
        var value = format(this.y);
        return `<span style="color:${configGraphic["colors"]["secondary"]}">●</span> ${this.series.name}: <b>${value}</b><br/>`;
      }
    },
    yAxis: {
      tickInterval: configChart["tickInterval_y"],
      tickColor: configGraphic["colors"]["secondary"],
      tickWidth: configGraphic["tickSet"]["tickWidth"],
      tickLength: configGraphic["tickSet"]["tickLength"],
      // minorTickPosition: 'outside',
      // minorTickInterval: configChart["tickInterval_y"],
      gridLineWidth: 0,      // minRange: (maxValue/minValue)*10,
      min: minValue,
      max: maxValue,
      // ceiling: globalData[0].data[globalData[0].data.length - 1][1],
      ceiling: maxValue,
      startOnTick: true,
      lineWidth: 1,
      minorGridLineWidth: 0,
      lineColor: configGraphic["colors"]["secondary"],
      labels: {
        style: {
          color: configGraphic["colors"]["secondary"],
          fontWeight: '400',
          fontFamily: 'Inter, sans-serif',
        },

        formatter: function () {
          if (configChart["formatter"] === false) {
            return this.value + " " + configChart["formatter_value"];
          } else {
            var value = this.value;
            var units = [' ', 'k', 'M', 'G', 'T', 'P', 'E']; // Définir les unités
            var i = 0;
            while (value >= 1000) {
              value /= 1000;
              i++;
            }
            return Math.round(value) + units[i]; // Afficher la valeur avec l'unité appropriée

          }
        }
      },
      title: {
        text: configChart["axe_y"],
        align: 'high',
        rotation: 0,
        x: configChart["position_horizontal_label_axe_y"],
        y: configChart["position_vertical_label_axe_y"],
        style: {
          color: configGraphic["colors"]["secondary"],
          fontWeight: '400',
          fontFamily: 'Inter, sans-serif',
          fontSize: configGraphic["fontSizesSet"]["other"],
        }
      },
    },
    xAxis: {
      labels: {
        style: {
          color: configGraphic["colors"]["secondary"],
          fontFamily: 'Inter, sans-serif',
          fontWeight: '400',
        }
      },
      tickColor: configGraphic["colors"]["secondary"],
      tickWidth: configGraphic["tickSet"]["tickWidth"],
      tickLength: configGraphic["tickSet"]["tickLength"],
      lineColor: configGraphic["colors"]["secondary"],
      tickInterval: configChart["tickInterval_x"],
      minRange: configChart["min_range"],
      min: globalData[0].data[0][0],
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      showFirstLabel: true,
      type: 'datetime',
      lineWidth: 1,
      title: {
        text: configChart["axe_x"],
        style: {
          fontWeight: '400',
          color: configGraphic["colors"]["secondary"],
          fontFamily: 'Inter, sans-serif',
        }
      },
    },
    plotOptions: {
      series: {
        color: configGraphic["colors"]["secondary"],
        animation: {
          duration
        },

        dataLabels: {
          rotation: 0,
          align: 'left',
          // color: configGraphic["colors"]["secondary"],
          textOutline: 'none',
          fontFamily: 'Inter, sans-serif',
          fontWeight: '400',
          enabled: true,
          formatter: function () {
            let text = ""
            if (configChart["formatter"] === false) {
              text = `${this.y} ${configChart["formatter_value"]} ${configChart["symbole_value"]}`
              // text = ""
            } else {
              var y = this.y;
              var units = [' FLOPS', ' kiloFLOPS', ' MégaFLOPS', ' GigaFLOPS', ' TéraFLOPS', ' PétaFLOPS', ' ExaFLOPS']; // Définir les unités
              var i = 0;
              while (y >= 1000) {
                y /= 1000;
                i++;
              }
              text = Math.round(y) + units[i]; // Afficher la valeur avec l'unité appropriée
            }

            return '<tspan style="textOutline: none;' + configGraphic["colors"]["secondary"] + '; fontFamily: Inter, sans-serif; strokeWidth: 0;fontWeight: 400 ' + configGraphic["colors"]["secondary"] + ';">' + text + '</tspan>';
          }
        }
      }
    },

    series: globalData.map((series,index) => {
      let color = ''
      const urlParams = new URLSearchParams(window.location.search);
      const logoParam = urlParams.get('logos');
      const colorsParam = urlParams.get('colors');

      if (globalData.length >=2 ) {
        color = colorsParam.split("|||")[index]
        // if (globalData[0].name == series.name) {
        //   color = "#2da531"
        // } else {
        //   color = "#fbbd0b"
        // }

      } else {

        color = configGraphic["colors"]["secondary"]
      }
      let logoName = series.name
      let imgLogo = ''

      if (logoParam)  {

        imgLogo = `<img src='${logoParam.split("|||")[index]}' width='20;' style="border-radius: 2px;"/>`
      }

      return {
        // name: `${series.name.toUpperCase()} - Gain ${series.value2[0]} %`,
        // name: `${series.value3[0]}Md$ ${series.name.charAt(0).toUpperCase() + series.name.slice(1)}`,
        // name: `<img src='${window.origin}/${logoName}.png' width='10;'/>  ${series.name.charAt(0).toUpperCase() + series.name.slice(1)}`,
        name: `<span style="
        display: flex;
        align-items: center;
        justify-content: space-between;">
        ${imgLogo}
        <span style="margin:0 4px;">${series.name.charAt(0).toUpperCase() + series.name.slice(1)}</span>
        </span>`,
        threshold: 0,
        negativeColor: '',
        color: color,
        dataLabels: {
          color: configGraphic["colors"]["secondary"],


        },
        marker: {
          symbol: 'circle',
          enabled: true
        },
        data: series.data.slice(0, startIterator).map(point => {
          return { x: point[0], y: point[1] }
        })
      }
    })
  });
  return chart
}

export { createChart }
