const parseData = (data, globalData, maxIterator) => {
  Highcharts.objectEach(
    data,
    // Prepare Highcharts data-format:
    // series: [{
    //   data: [ [x, y], [x, y], ..., [x, y]]
    // }]
    (countryData, country) => {

      globalData.push({
        name: country,
        info: countryData.map(p => p.info),
        info2: countryData.map(p => p.info2),
        value2: countryData.map(p => p.value2),
        value3: countryData.map(p => p.value3),
        data: countryData.map(p => [Date.parse(p.date), p.value_base_100 -100])

      })
    }
  );


  // Sort and limit dataset:
  globalData = globalData
    .sort((countryA, countryB) => {
      let countryALen,
        countryBLen;

      if (!countryA || !countryA.data || countryA.data.length === 0) {
        return 1;
      }

      if (!countryB || !countryB.data || countryB.data.length === 0) {
        return -1;
      }

      return countryB.data[countryB.data.length - 1][1] - countryA.data[countryA.data.length - 1][1];
    })
    .splice(0, 8);


  maxIterator = Math.max.apply(null, globalData.map(series => series.data.length - 1));

  return {"globalData": globalData, "maxIterator": maxIterator};
}

export { parseData }
