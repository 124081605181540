import { Controller } from "@hotwired/stimulus"
import Typed from 'typed.js';

// Connects to data-controller="typejs"
export default class extends Controller {
  connect() {
    const element = '#typed'
    const elementPolitic = '#typed-2'

    if (document.querySelector(element)) {
      var typed = new Typed(element, {
        strings: ["<strong>Récupération</strong> de la donnée géolocalisée",
        "<strong>Nettoyage</strong> de la donnée",
        "<strong>Enrichissement</strong> de la donnée",
        "Calcul des <strong>distances</strong>",
        "Calcul des <strong>statistiques</strong>",
        "Création de la <strong>carte</strong>..."],
        typeSpeed: 10
      });

    }
    if (document.querySelector(elementPolitic)) {
      var typedPolitic = new Typed(elementPolitic, {
        strings: ["<strong>Récupération</strong> de la circonscription",
          "Découpage des <strong>bureaux de votes</strong>",
        "Calcul des <strong>écarts de voix</strong>",],
        typeSpeed: 15
      });

    }



  }
}
