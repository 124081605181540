import { Controller } from "@hotwired/stimulus"
import JSConfetti from 'js-confetti'
import { errorAlertSweet, loadingAlertSweet } from "../components/alert/sweet.js"
// import { timer } from "d3";
import Swal from 'sweetalert2'

// Connects to data-controller="insert-photo"
export default class extends Controller {
  static targets = ["photostyle", "form"]


  connect() {

    Dropzone.autoDiscover = false;
    const refreshPage = () => {
      document.getElementById("spinner-loading").classList.toggle("d-none")
      document.getElementById("timer-waiting").classList.toggle("d-none")
      document.getElementById("spinner-loading").insertAdjacentHTML("beforebegin", `
          <a href="${window.location.href}" style="background:#03117C; cursor:pointer;border: none; color: white; font-weight: bolder;">
          Relancer une création</a>
          `)
      document.getElementById("spinner-loading").nextElementSibling.remove();
    }
    let photoDropzone = new Dropzone("#form-dropzone", {
      autoProcessQueue: false,
      uploadMultiple: true,
      dictRemoveFile: "Supprimer",
      dictFileTooBig: "Fichier trop gros ({{filesize}}MiB). Taille max: {{maxFilesize}}MiB.",
      dictCancelUpload: "",
      maxFiles: 2,
      maxFilesize: 20,
      thumbnailHeight: 250,
      thumbnailwidth: 250,
      resizeHeight: 500,
      resizeQuality: 0.6,
      maxThumbnailFilesize: 20,
      resizeMethod: 'contain',
      resizeMimeType: 'image/png',
      parallelUploads: 2,
      addRemoveLinks: true,
      uploadForm: this.formTarget,
      init: function () {
        this.on("addedfile", file => {
          // https://docs.dropzone.dev/configuration/basics/layout

          const photo1 = document.getElementById("input-photo-1");
          const photo2 = document.getElementById("input-photo-2-tableau");
          document.querySelector(".dz-progress").remove();
          file.previewElement.classList.add("dz-success");


          if (photo1.querySelector(".dz-preview")) {
            photo2.classList.toggle("input-photo")
            photo2.querySelector(".add-tof").classList.toggle("d-none")

            file.previewElement.classList.add("photo-2-tableau")
            photo2.insertAdjacentElement("afterbegin", file.previewElement)
          } else {
            photo1.classList.toggle("input-photo")
            photo1.querySelector(".add-tof").classList.toggle("d-none")
            file.previewElement.classList.add("photo-1")
            photo1.insertAdjacentElement("afterbegin", file.previewElement)
          }
        });
        this.on("removedfile", (file) => {
          if (file.previewElement.classList.contains("photo-1")) {
            const photo1 = document.getElementById("input-photo-1");
            photo1.classList.toggle("input-photo");
            file.previewElement.remove()
          } else if (file.previewElement.classList.contains("photo-2-tableau")) {
            const photo2 = document.getElementById("input-photo-2-tableau");
            photo2.classList.toggle("input-photo");
            file.previewElement.remove()
          }
        });
        this.on("errormultiple", function (files, response) {
          Swal.close();
          refreshPage();
          errorAlertSweet("Une erreur est survenue. Veuillez réessayer.", true);
        });
        this.on("successmultiple", function (files, response) {
          Swal.close()

          console.log('response', response)
          photoDropzone.clickable = false;

          const photoBoxStyle = document.getElementById("box-style")
          // photoBoxStyle.classList.remove("input-photo");
          photoBoxStyle.insertAdjacentHTML("afterbegin", `
            <img src=${response.img.photo_style} height=120 width=120 style="border-radius: 23px;"> <br>
          `)
          // document.getElementById("form-dropzone")
          const photoStyleDisplay = document.getElementById("photo-style-display")
          photoStyleDisplay.querySelector("img").src = response.img.photo_style
          photoStyleDisplay.querySelector("input").value = response.url_show
          photoStyleDisplay.classList.toggle("d-none")
          photoStyleDisplay.insertAdjacentHTML("afterend", `
            <div class="d-flex justify-content-center align-items-center pt-3 pb-3">
              <a href='${response.url_show}' target="_blank" >
              Voir le montage
              </a>
            </div>
          `)
            // < a href = "${response.img.photo_style}" download = "image-thedeclic.png" target = "_blank" > <b>Télécharger l'image</b></a >

          refreshPage()

          const jsConfetti = new JSConfetti();
          jsConfetti.addConfetti();
          jsConfetti.addConfetti({
            emojis: ['❤️', '❤️', '❤️', '❤️', '✨'],
            emojiSize: 70,
            confettiNumber: 50
          })

        });
      }
    })
    window.photoDropzone = photoDropzone

  }
  send(event) {
    event.preventDefault()
    event.stopPropagation();
    if (photoDropzone.files.length == 2) {
      photoDropzone.processQueue()
      document.querySelector('input[type="submit"]').disabled = true;
      document.querySelector(".dz-hidden-input").disabled = true


      let timerTime = 60000;
      loadingAlertSweet(timerTime);
      document.getElementById("spinner-loading").classList.toggle("d-none")
      document.getElementById("timer-waiting").classList.toggle("d-none")

      timerTime = parseInt((timerTime - 2000) / 1000)
      const timerIntervalTwo = setInterval(() => {
        document.getElementById("timer-waiting").innerHTML = `Temps restant maximum <b>${timerTime}</b>.`;
        timerTime--;
        if (timerTime === 0) {
          clearInterval(timerIntervalTwo);
        }
      }, 1000);



    } else {
      // let timerTime = 60000;
      // loadingAlertSweet(timerTime);


      errorAlertSweet("Veuillez ajouter uniquement 2 photos", false);

    }
  }

}
