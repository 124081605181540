import { Controller } from "@hotwired/stimulus"
import { gantt } from "../components/dataviz/hightchart/gantt"
import { startHightchartStoryTime } from "../components/dataviz/hightchart/animation/timeseries/one/start"
import { startHightchartStoryTimeBar } from "../components/dataviz/hightchart/animation/timeseries/bar/start"
// Connects to data-controller="gantt"
export default class extends Controller {
  // static values = {
  //   timeseries: Json
  // }
  connect() {

    const containerGantt = document.getElementById("container-gantt")
    const containerStoryTime = document.getElementById("container-story-time")
    const containerStoryTimeBar = document.getElementById("container-story-time-bar")

    if (containerGantt) {
      gantt();
    }
    if (containerStoryTime) {
      const dataApiTimeSeries = containerStoryTime.dataset.ganttTimeseriesValue
      // const dataApiLogos = containerStoryTime.dataset.logos
      startHightchartStoryTime(dataApiTimeSeries);
    }
    if (containerStoryTimeBar) {
      const dataApiTimeSeries = containerStoryTimeBar.dataset.ganttTimeseriesValue
      // const dataApiLogos = containerStoryTime.dataset.logos
      startHightchartStoryTimeBar();
    }

  }
}
