import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);
require("highcharts/highcharts-more")(Highcharts);
window.Highcharts = Highcharts

// const rgpd_cookies = () => {
//   window.axeptioSettings = {
//     clientId: "651482b5a9fd626724eef394",
//   };

//   (function(d,s) {
//     var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
//     e.async = true; e.src = "//static.axept.io/sdk.js";
//     t.parentNode.insertBefore(e, t);
//   })(document, "script");

// }
// rgpd_cookies();


export { application }
