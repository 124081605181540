import { Controller } from "@hotwired/stimulus";
import { spider } from "../components/dataviz/hightchart/spider";
import { dinosaurus } from '../components/animation/dinosaurus';
export default class extends Controller {
  connect() {
    spider();

    if (this.element.id == "animation-dinausorus") {
      dinosaurus()

    }
    // this.element.textContent = "Hello World!"
  }
}
