
const spider = () => {
  const containerSpider = document.getElementById('container-spider')
  if (containerSpider) {
    let dataValues = containerSpider.dataset.values
    dataValues = JSON.parse("[" + dataValues + "]");
    const skillzNameList = dataValues[0].map(x => x.skill.join(" "))

    const skillzForceList = dataValues[0].map(x => x.force)

    Highcharts.chart("container-spider", {

        chart: {
            polar: true,
            type: 'line',
            ignoreHiddenSeries: false
        },
        accessibility: {
            description: 'A spiderweb chart compares the allocated budget against actual spending within an organization. The spider chart has six spokes. Each spoke represents one of the 6 departments within the organization: sales, marketing, development, customer support, information technology and administration. The chart is interactive, and each data point is displayed upon hovering. The chart clearly shows that 4 of the 6 departments have overspent their budget with Marketing responsible for the greatest overspend of $20,000. The allocated budget and actual spending data points for each department are as follows: Sales. Budget equals $43,000; spending equals $50,000. Marketing. Budget equals $19,000; spending equals $39,000. Development. Budget equals $60,000; spending equals $42,000. Customer support. Budget equals $35,000; spending equals $31,000. Information technology. Budget equals $17,000; spending equals $26,000. Administration. Budget equals $10,000; spending equals $14,000.'
        },

        title: {
            text: undefined,
            x: -80
        },

        pane: {
            size: '80%'
        },

        xAxis: {
            categories: skillzNameList,
            tickmarkPlacement: 'on',
            lineWidth: 0
        },

        yAxis: {
            gridLineInterpolation: 'polygon',
            lineWidth: 0,
            min: 0
        },

        tooltip: {
            shared: true,
            pointFormat: '{text}',
            formatter: function (data) {
              const listData = data.chart.series[0].processedYData
              const sumListData = listData.reduce((a, b) => a + b, 0)
              const mean = Math.round((this.y / sumListData) * 100)
              // <span style="color:{series.color}"> <b>{point.y:,.0f}</b><br/>
              const sentence = `<span style="color:{series.color}">
              <b>${this.x}</b>: <br>${mean} % de la formation
              </span>`
              return sentence
            }
        },

        legend: {
            align: 'top',
            verticalAlign: 'middle',
            layout: 'vertical',
            enabled: false
        },

        series: [{
            // name: 'Nombre de fonctionnalités',
            data: skillzForceList,
            pointPlacement: 'on',
            color: '#03117C'
        }],
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal'
                    },
                    pane: {
                        size: '60%'
                    }
                }
            }]
        }

    });
  }

}

// <span style="color:{series.color}"> <b>{point.y:,.0f}</b><br/>

export { spider }
