// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "bootstrap"
import "trix"
import "@rails/actiontext"

// let items = [{ 'name': "Objectifs clairs et alignés",
// "description":"Définissez des objectifs d'optimisation précis.",
//   "color": "#FDF4F2",
// },{
//   "name": "Excellence dans la gestion des données",
//   "description":`Captez un maximum de données détaillées.`,
//   "color": "#2DA530",
// },{
//   "name": "Architecture IT modulaire et ouverte",
//   "description": `Misez sur une stack technique "faiblement couplée". L'agilité est primordiale.`,
//   "color": "#FBBC04",
// },{
//   "name": "Culture d'expérimentation continue",
//   "description":`Autorisez les tests fréquents, même non concluants car source d'apprentissage. Valorisez la créativité.`,
//   "color": "#03117C"
// }
// ]
// let i = 0

// document.querySelectorAll('.actions-template-column').forEach((column) => {
//   column.querySelector('.f4-mktg').innerText = items[i]["name"]
//   column.querySelector('.f6').innerText = items[i]["description"]
//   column.querySelector('.actions-template-avatar').querySelector('img').style.display = "none"
//   column.querySelector('.actions-template-avatar').style.backgroundColor = items[i]["color"]
//   column.querySelector('.actions-template-avatar').classList.remove('color-bg-emphasis')
//   column.querySelector('.actions-template-avatar').classList.remove('color-bg-subtle')
//   column.querySelector('.actions-template-avatar').classList.remove("color-bg-attention-emphasis")
//   column.querySelector('.actions-template-avatar').classList.remove("color-bg-accent-emphasis")
//   column.querySelector('.actions-template-avatar').classList.remove("bg-icon-orange")
//   // column.querySelector('.actions-template-repo').remove()
//   // column.querySelector('.lh-condensed-ultra').remove()
//   column.querySelector('.actions-template-repo').innerHTML = "data4declic.com"

//   if (column.querySelector('.color-bg-subtle')) {
//     column.querySelector('.color-bg-subtle').style.backgroundColor = items[i]["color"]

//   }


//   i += 1
//   if (i == 4) {
//     i = 0
//   }

// })


