import { color } from "highcharts";
import { getDataTeleperformance } from "./data/getDataTeleperformance";

import { getDataEnable } from "./data/getDataEnable";

import { configChart } from "./config/configChart";
import { configGraphic } from "./config/configGraphic";
import { btnManageEvent } from "./btnManageEvent";
import { createChart } from "./createChart";
import { parseData } from "./parseData";

const startHightchartStoryTime = (dataApiTimeSeries, dataApiLogos) => {


  // const colors = configGraphic()["colors"];
  // const fontSizesSet = configGraphic()["fontSizesSet"];
  let globalData = [];

  let startIterator = 1;
  let maxIterator = 1;
  // Step 1: Get the data
  // const data = getDataEnable();
  const data = JSON.parse(dataApiTimeSeries)
  // Step 2: Parse the data

  globalData = parseData(data, globalData, maxIterator)["globalData"];

  // Step 3: Create the chart
  let chart;
  chart = createChart(chart, configGraphic(), globalData, configChart()["duration"], startIterator, configChart());

  btnManageEvent(maxIterator, startIterator, chart, globalData, configChart()["duration"], configChart());

}



export { startHightchartStoryTime }
