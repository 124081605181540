import { redrawChart } from "./redrawChart";
const btnManageEvent = (maxIterator, startIterator, chart, globalData, duration, configChart) => {
  let currentIterator = startIterator;

  let guiButton = document.getElementById('start');
  let guiButtonState = 'Start';
  let intervalId;
  guiButton.addEventListener('click', function () {
    if (guiButtonState === 'Stop') {
      // User clicked "Stop" -> stop animation and allow to resume
      intervalId = clearInterval(intervalId);
      guiButton.innerText = guiButtonState = 'Resume';
    } else {
      // If animation has finished, recreate chart
      if (guiButtonState === 'Restart') {
        createChart();
      }
      guiButton.innerText = guiButtonState = 'Stop';
      // Start animation:
      redrawChart(currentIterator += 1, chart, globalData, duration, configChart);
      intervalId = setInterval(function () {
        // If we reached last available point, stop animation:
        if (currentIterator === maxIterator) {
          intervalId = clearInterval(intervalId);
          currentIterator = startIterator;
          guiButton.innerText = guiButtonState = 'Restart';
        } else {
          redrawChart(currentIterator += 1, chart, globalData, duration, configChart);
        }
      }, duration);
    }
  });
}
export { btnManageEvent }
