const buildPolygonCircle = (d3,node) => {
  node.append(function (d) {
    if (d.data.type == "info") {
      return d3.create('polygon')
        .attr("fill", "white")
        .attr("stroke", "#999")
        .attr("stroke-width", 1) // set the stroke width to 1
        .attr("points", "0,-28 24,-14 24,14 0,28 -24,14 -24,-14").node();
    } else {
      const g = d3.create('g');
      g.append('circle')
        .attr("fill", "white")
        .attr("r", 28);
      if (d.data.image) {
        const imageUrl = d.data.image;
        g.append('image')
          .attr("xlink:href", (d, i, nodes) => `${imageUrl}`)
          .attr("height", 50)
          .attr("x", -25)
          .attr("y", -25)
          .attr("width", 50);
      }
      return g.node();
    }
  });
  return node
}
export { buildPolygonCircle }

  // const manage_image = (d) => {
  //   // d => d.data.name != "Eve" ? `${window.location.origin}/${d.data.image}` : ``
  //   if (d.data.name != "Eve") {

  //     return `${d.data.image}`
  //   } else {
  //     return ""
  //   }
  // }
