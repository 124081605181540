import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flip-card"
export default class extends Controller {
  connect() {
    this.element.addEventListener('change', (event) => {
      const flashCard = event.currentTarget.parentElement.parentElement
      flashCard.classList.toggle('transform-flash-card')
    })
  }
}
