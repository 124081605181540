const buildText = (d3,node) => {
  const format_name_info = (word, i, nodes, info) => {
    let words = word.split(" ");
    if (words.length > 2) {
      const lastWords = words.slice(2,words.length).join(" ")

      words = [words.slice(0,2).join(" "), lastWords]
    }

    let html_words = []
    words.forEach((word, index) => {
      const wordCenter = -word.length - 5;
      let y = ''
      if (index === 0) {
        y = (info === "chart") ? -5 : 30;
      } else if (index === 1) {
        y = (info === "chart") ? 10 : 45;
      } else if (index === 2) {
        y = (info === "chart") ? 25 : 60;
      } else if (index === 3) {
        y = (info === "chart") ? 40 : 75;
      }
      html_words.push(`<tspan x="${wordCenter}" y=${y}>${word}</tspan>`)

    });
    return html_words.join(" ")
  }


  node.append("text")
    .attr("style", "color:white;")
    .attr("dy", d => d.data.type != "info" ? 5 : 0)
    .attr("x", d => d.data.type != "info" ? 30 : -58)
    .attr("text-anchor", d => d.data.type != "info" ? "end" : "start")

    .html((d, i, nodes) => d.data.type != "info" ? format_name_info(d.data.name, i, nodes, "info") : format_name_info(d.data.name, i, nodes, "chart"))
    .attr("style", d => d.data.name.length < 20 ? "font-size: 0.8em;" : "font-size: 0.65em;" )
    .clone(true).lower()
    .attr("stroke", "white");
// "font-size: 0.8em;"
  return node
}

export { buildText }
