
const buildG = (svg, root) => {
  const node = svg.append("g")
    .attr("stroke-linejoin", "round")
    .attr("stroke-width", 3)
    .selectAll()
    .data(root.descendants().slice(1))
    .join("g")
    .attr("transform", d => `translate(${d.x},${d.y})`);
  return node;
}

export { buildG }
