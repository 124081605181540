
const checkboxChoice = (categoryChoices) => {
  // const categories = document.querySelectorAll('.category-choices > label')
  const categories = categoryChoices.querySelectorAll('label')
  categories.forEach((category) => {
    category.addEventListener('click', (event) => {

      event.currentTarget.classList.toggle('active')
      setBackgroundColor(event)
    })
  })
}

const setBackgroundColor = (event) => {
  const text = event.currentTarget.dataset.categoryTag
  if (text == "data") {
    event.currentTarget.classList.toggle('background-color-data')
  } else if (text == "web") {
    event.currentTarget.classList.toggle('background-color-web')

  } else if (text == "learn") {
    event.currentTarget.classList.toggle('background-color-learn')
  } else if (text == "tech") {
    event.currentTarget.classList.toggle('background-color-tech')
  } else if (text == "management") {
    event.currentTarget.classList.toggle('background-color-management')
  } else {
    event.currentTarget.classList.toggle('background-color-black')
  }
}
export { checkboxChoice }
