import { Controller } from "@hotwired/stimulus"
import JSConfetti from 'js-confetti'
// #doc : https://github.com/loonywizard/js-confetti
// Connects to data-controller="confetti"

export default class extends Controller {
  dropConfetti() {
    if (window.location.pathname.includes("confirmation-contact")) {
      const jsConfetti = new JSConfetti();
      jsConfetti.addConfetti();
      jsConfetti.addConfetti({
        emojis: ['🌈', '⚡️', '🎊', '✨', '💫', '🌸',"🦄"],
        emojiSize: 100,
        confettiNumber: 30
      })
    } else {

      if (localStorage.getItem('confetti') != "true") {
        const jsConfetti = new JSConfetti();
        jsConfetti.addConfetti();
        jsConfetti.addConfetti({
          confettiColors: [
            '#00FF00', '#63DDF6', '#4D05C4', '#FDF4E9','#00FF00','#63DDF6','#4D05C4', '#FDF4E9'
          ],
        })
        localStorage.setItem('confetti', "true");
      }
    }

  }
  connect() {
    this.dropConfetti();

  }
}
