import * as d3 from "d3";
// import { flextree } from "d3-flextree";
import { buildSvg } from "./constructor/svg";
import { buildLink } from "./constructor/link";
import { buildG } from "./constructor/g";
import { buildPolygonCircle } from "./constructor/polygonCircle";
import { buildText } from "./constructor/text";
const createTree = (data, idName, thisElement) => {
  const svgRoot = buildSvg(d3, data, idName);
  const svg = svgRoot[0];
  const root = svgRoot[1];

  const link = buildLink(d3, svg, root);

  const node = buildG(svg, root);

  const polygonCircle = buildPolygonCircle(d3, node);

  const text = buildText(d3,node);

  svg.node()
  thisElement.insertAdjacentHTML("afterbegin", svg.node().outerHTML);
}

export { createTree }
