import * as Highcharts from 'highcharts/highcharts-gantt';

const gantt = () => {
    const color1 = '#2DA530';
    const color2 = '#FFD400';
    const color3 = '#03117C';
    var today = new Date()
    let day = 1000 * 60 * 60 * 24
    today.setUTCHours(0);
    today.setUTCMinutes(0);
    today.setUTCSeconds(0);
    today.setUTCMilliseconds(0);
    today = today.getTime();

  Highcharts.ganttChart('container-gantt-mobile', {
    title: {
      text: 'Construisons ensemble votre projet',
      style: {
          fontFamily: 'Inter',
          fontSize: '2em',
          fontWeight: 'bolder'
        }
    },
    subtitle: {
      text: 'Le projet est décomposé en tâches spécifiques pour une efficacité optimale',
    },
    tooltip: {
        enabled: false
    },
    lang: {
        accessibility: {
            axis: {
                xAxisDescriptionPlural: 'Le graphique a un axe X en deux parties montrant le temps en semaines et en jours.'
            }
        },
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        shortMonths: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
        rangeSelectorZoom: 'Période',
        resetZoom: 'Réinitialiser le zoom'
    },
    yAxis: {
      type: 'category',
      categories: ['Expression du besoin', 'Développement', 'Testing'],
      accessibility: {
        description: 'Départements de l\'organisation'
      },
      min: 0,
      max: 2
    },
      xAxis:[{
        visible: false,
        type: 'category',
    }],
    series: [{
      name: 'Expression du besoin',
      data: [{
        name: 'Expression du besoin',
        start: today,
        end: today + 1,
        color: color1,
        id: 'exp-1',
        y: 0
      }, {
        name: 'Développement',
        start: today + 1,
        end: today + 2,
        color: color2,
        id: 'dev-1',
        dependency: 'exp-1',
        y: 1
      }, {
        name: 'Testing',
        start: today + 2.2,
        end: today + 2.5,
        color: color3,
        id: 'test-1',
        dependency: 'dev-1',
        y: 2
        }, {
        name: 'Développement',
        start: today + 2.5,
          end: today + 3.5,
          color: color2,
          id: 'dev-2',
          dependency: 'test-1',
          y: 1
        }, {
          name: 'Testing',
          start: today + 3.7,
          end: today + 4,
          color: color3,
          id: 'test-3',
          dependency: 'dev-2',
          y: 2
        }]
    }]
  });

}

export { gantt }

