import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="icone-vibrate"
export default class extends Controller {
  connect() {
    this.element.addEventListener("mouseenter", (event) => {
      event.currentTarget.querySelector(".card-service-icone").classList.add('vibrate');
      event.currentTarget.querySelector(".card-service-icone").querySelector('svg').classList.add('vibrate');
    });
    this.element.addEventListener("mouseleave", (event) => {
      event.currentTarget.querySelector(".card-service-icone").classList.remove('vibrate');
      event.currentTarget.querySelector(".card-service-icone").querySelector('svg').classList.remove('vibrate');

    });
  }
}
