const configGraphic = () => {
  const colors = {
    "main": "#03117C",
    "secondary": "white",
  }
  const fontSizesSet = {
    "max": "18px",
    "subtitle": "16px",
    "other": "13px",
    "min": "12px"
  }
  const tickSet = {
    "tickWidth": 1,
    "tickLength": 5,
  }
  return { "colors": colors, "fontSizesSet": fontSizesSet, "tickSet": tickSet }
}
export { configGraphic }
