
const buildSvg = (d3, data, idName) => {
  const width = 928;
  // Compute the tree height; this approach will allow the height of the
  // SVG to scale according to the breadth (width) of the tree layout.
  const root = d3.hierarchy(data);
  const dx = 100;
  const dy = width / (root.height + 1) - 200;

  // Create a tree layout.
  const tree = d3.tree().nodeSize([dx, dy]);
  // Sort the tree and apply the layout.
  root.sort((a, b) => d3.ascending(a.data.order, b.data.order));
  tree(root);

  // Compute the extent of the tree. Note that x and y are swapped here
  // because in the tree layout, x is the breadth, but when displayed, the
  // tree extends right rather than down.
  let x0 = Infinity;
  let x1 = -x0;


  root.each(d => {
    if (d.x > x1) x1 = d.x;
    if (d.x < x0) x0 = d.x;
  });

  // Compute the adjusted height of the tree.
  const height = 350;

  const svg = d3.create("svg")
    .attr("width", "90%")
    .attr("height", height)
    .attr("id", idName)
    // .attr("viewBox", [-dy / 3, x0 - dx, width, height])
    .attr("viewBox", [-width / 2, -20, width, height])
    .attr("style", "max-width: 90%; height: auto; font: 10px sans-serif;")
    .attr("class", "d-none");

  return [svg, root];

}
export { buildSvg }
