const dataNumericGet = () => {
  const data = JSON.parse(`{
    "name": "",
    "children": [
      {"order": "1", "name": "Une variable","type":"info", "image": "test.png",
        "children": [
          {"name": "HISTOGRAM"},
          {"name": "Density plot"}
        ]},
      {"order": "2", "name": "Deux variables","type":"info", "image": "test.png",
        "children": [
          {"name": "Enos"},
          {"name": "Noam"}
        ]},
      {"order": "3","type":"info", "name": "Plusieurs variables",
        "children": [
          {"name": "Enoch"}
        ]}]
  }`)

  return data

}


export { dataNumericGet }
