import { Controller } from "@hotwired/stimulus"
import { Dropzone } from "dropzone";
// import "dropzone/dist/dropzone.css";

// Connects to data-controller="input-img"
export default class extends Controller {
  connect() {
    this.element.addEventListener("submit", (event) => {
      event.preventDefault();

      photoDropzone.processQueue();


    })

    let photos = []
    Dropzone.autoDiscover = false;
    let photoDropzone = new Dropzone("#form-dropzone", {
      autoProcessQueue: false,
      uploadMultiple: true,
      maxFiles: 2,
      maxFilesize: 2,
      parallelUploads:10000,
      addRemoveLinks: true,
    })
    photoDropzone.options.myElement = {
      // Note: using "function()" here to bind `this` to
      // the Dropzone instance.
      init: function () {
        this.on("successmultiple", (file, response) => {
          done();
          console.log("A successmultiple file has been added");
        });
      }
    };



    photoDropzone.on("addedfile", file => {
      // photos.push(file["dataURL"]);
      console.log(file);
      // photoDropzone.processQueue();


      // console.log(`File added: ${photos.slice(0, 2)}`);
    });



  }
}
