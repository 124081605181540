import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2'

// Connects to data-controller="sweet-alert"
export default class extends Controller {
  connect() {
    let content_html =`
    <ul class="db-free-tableau liste-style-none">

      <li>
        <a href="https://www.parismuseescollections.paris.fr/fr" target="_blank">
          Paris Musees Collections <i style="font-size:9px; position: relative;bottom: 6px;" class="ri-external-link-line"></i>
        </a>
      </li>
      <li>
        <a href="https://www.artic.edu/collection" target="_blank">
          Art institvte Chicago <i style="font-size:9px; position: relative;bottom: 6px;" class="ri-external-link-line"></i>
        </a>
      </li>
      <li>
        <a href="https://www.metmuseum.org/art/collection/search?" target="_blank">
          The Metropolitan Museum of Art <i style="font-size:9px; position: relative;bottom: 6px;" class="ri-external-link-line"></i>
        </a>
      </li>
      <li>
        <a href="https://unsplash.com/fr" target="_blank">
          Unsplah <i style="font-size:9px; position: relative;bottom: 6px;" class="ri-external-link-line"></i>
        </a>
      </li>
      <li>
        <a href="https://commons.wikimedia.org/w/index.php?search=picasso&title=Special:MediaSearch&go=Go&type=image" target="_blank">
          Commons Wikimedia <i style="font-size:9px; position: relative;bottom: 6px;" class="ri-external-link-line"></i>
        </a>
      </li>
    </ul>`

    this.element.addEventListener('click', (event) => {
      event.preventDefault();
      Swal.fire({
        title: `Images libres de droits`,
        html: `${content_html}`,
        confirmButtonColor: "black",
        showClass: {
          popup: `
        animate__animated
        animate__fadeInUp
        animate__faster
      `
        },
        hideClass: {
          popup: `
        animate__animated
        animate__fadeOutDown
        animate__faster
      `
        }
      });

    })
  }
}
